<template>
  <div>
    <b-container>
      <h2 style="text-decoration: underline;">
        Skills
      </h2>
      <p style="font-size:20px">
        <i class="devicon-python-plain"></i> Python , 
        <i class="devicon-cplusplus-plain"></i> C++ , 
        <i class="devicon-c-plain"></i> C , 
        <i class="devicon-go-plain"></i> Go , 
        some knowledge of <i class="devicon-java-plain"></i> Java and  <i class="devicon-javascript-plain"></i> JavaScript ( <i class="devicon-vuejs-plain"></i> Vue.js,  <i class="devicon-nodejs-plain"></i> Node.js)
      </p>
      <p style="font-size:20px">
        <i class="devicon-terraform-plain"></i> Terraform , 
        <i class="devicon-ansible-plain"></i> Ansible , 
        hands-on experience with <i class="devicon-amazonwebservices-original"></i> AWS
      </p>
      <p style="font-size:20px">
        <i class="devicon-git-plain"></i> Git , 
        <i class="devicon-docker-plain"></i> Docker ( + docker-compose, <i class="devicon-k3s-original"></i> k3s, <i class="devicon-kubernetes-plain"></i> k8s ) , 
        <i class="devicon-jenkins-line"></i> Jenkins
      </p>
      <p style="font-size:20px">
        Variety of C++ and Python Libraries, Boost, Qt, etc.
      </p>
      <p style="font-size:20px">
        <i class="devicon-django-plain"></i> Django (+DRF) and <i class="devicon-flask-original"></i> Flask, 
        <i class="devicon-go-plain"></i> Go web development libraries and frameworks (e.g. Echo)
      </p>
      <p style="font-size:20px">
        SQL (<i class="devicon-sqlite-plain"></i> SQLite, 
        <i class="devicon-postgresql-plain"></i> PostgreSQL, 
        <i class="devicon-mysql-plain"></i> MySQL), 
        NoSQL (<i class="devicon-mongodb-plain"></i> MongoDB)
      </p>
      <p style="font-size:20px">
        <font-awesome-icon icon="database" /> Additionally, Python and R libraries and tools used for Data Engineering, Machine Learning and Deep Learning.
      </p>
      <p style="font-size:20px">
        <i class="devicon-linux-plain"></i> Linux , 
        <i class="devicon-windows8-original"></i> Windows
      </p>
      <p style="font-size: 20px;">
        <font-awesome-icon icon="language" size="lg" style="margin-right: 1rem;" /> Languages: Ukrainian, English, French, Polish, Russian
      </p>
    </b-container>
    <hr>
    <b-container>
      <h2 style="text-decoration: underline;">
        Experience
      </h2>
      <p style="font-size: 20px;">
        <font-awesome-icon icon="map-marker-alt" size="lg" style="margin-right: 1rem;" /> DevOps Engineer at Paramount
        <br>
        Current Position
      </p>
      <p style="font-size: 20px;">
        <font-awesome-icon icon="map-marker-alt" size="lg" style="margin-right: 1rem;" /> Build Engineer (DevOps team) at CD Projekt Red (CD Projekt S.A.)
        <br>
        8 months
      </p>
      <p style="font-size: 20px;">
        <font-awesome-icon icon="map-marker-alt" size="lg" style="margin-right: 1rem;" /> Software Engineer at CodiLime
        <br>
        3 months
      </p>
      <p style="font-size: 20px;">
        <font-awesome-icon icon="map-marker-alt" size="lg" style="margin-right: 1rem;" /> Software Engineer at GOG.com (CD Projekt S.A.)
        <br>
        6 months
      </p>
      <p style="font-size: 20px;">
        <font-awesome-icon icon="map-marker-alt" size="lg" style="margin-right: 1rem;" /> Software Engineer / Data Engineer at Samsung Electronics
        <br>
        3 years
      </p>
      <p style="font-size: 20px;">
        <font-awesome-icon icon="bookmark" size="lg" style="margin-right: 1rem;" /> 1+ years Freelance Developer
      </p>
    </b-container>
    <hr>
    <b-container>
      <h2 style="text-decoration: underline;">
        Education
      </h2>
      <!-- <p style="font-size: 20px;">
        <font-awesome-icon icon="book-open" size="lg" style="margin-right: 1rem;" /> Graduate (PhD) studies in Computer Science <br> Department of Computing and Software, McMaster University (Canada) <br> 2020 - current Time
      </p> -->
      <p style="font-size: 20px;">
        <font-awesome-icon icon="user-graduate" size="lg" style="margin-right: 1rem;" /> Bachelor's Degree in Computer Science (English) <br> University of Lodz (Poland) <br> 2016 - 2019
      </p>
      <p style="font-size: 20px;">
        <font-awesome-icon icon="user-graduate" size="lg" style="margin-right: 1rem;" /> Ternopil School #3 (Specialized in Foreign Languages) <br> Ternopil, Ukraine <br> 2005 - 2016
      </p>
    </b-container>
    <hr>
    <b-container>
      <h2 style="text-decoration: underline;">
        Additional Information
      </h2>
      <p style="font-size: 20px;">
        Part of an OpenSource community.
        Constantly learning, expanding my skillset.
        Looking for projects/areas to apply my skills and improve, by gaining more experience.
        Extremely interested in Northers European and Asian cultures.
      </p>
    </b-container>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faDatabase,
  faLanguage,
  faMapMarkerAlt,
  faBookmark,
  faUserGraduate,
  faBookOpen
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faDatabase,
  faLanguage,
  faMapMarkerAlt,
  faBookmark,
  faUserGraduate,
  faBookOpen
);

export default {
  name: "Information"
}
</script>

<style scoped>
  .container {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  h2 {
    margin-bottom: 2rem;;
  }
  p {
    margin-bottom: 1.25rem;;
  }
</style>
